import { lystoApi } from "../config";

export const productApi = lystoApi.injectEndpoints({
  endpoints: (build) => ({
    getProductById: build.query({
      query: (id) => `producto/${id}`,
    }),
    getCategories: build.query({
      query: () => "ProductoCategoria",
    }),
  }),
  overrideExisting: false,
});

export const { useGetProductByIdQuery, useGetCategoriesQuery } = productApi;
