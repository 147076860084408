import Heading from "../ui/heading";
import "rc-table/assets/index.css";
import Table from "rc-table";

const CreatedAt = ({ createdAt }) => {
  const date = new Date(createdAt);
  return (
    <span className="whitespace-nowrap">
      {date.toLocaleDateString("es-CL", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
    </span>
  );
};

const colorStatus = {
  1: "#A6B1BD",
  3: "#FED030",
  4: "#02B290",
  5: "red",
};

/* const purchaseStatuses = {
  1: { name: 'Recibido' },
  2: { name: 'Procesando' },
  3: { name: 'En despacho' },
  4: { name: 'Entregado' },
  5: { name: 'Fallido' },
  6: { name: 'En Espera' },
  7: { name: 'Cancelado' },
  10: { name: 'Sin estado' },
};
 */
const Status = ({ item }) => {
  return (
    <span className={item?.currentEstado?.replace(/\s/g, "_").toLowerCase()}>
      <span
        className="bullet"
        style={{ backgroundColor: colorStatus[item?.idEstado] }}
      />
      {item?.currentEstado}
    </span>
  );
};

const columns = [
  {
    title: "Orden",
    dataIndex: "id",
    key: "id",
    className: "id-cell",
  },
  {
    title: "Fecha",
    dataIndex: "createdAt",
    key: "createdAt",
    render: function createdAt(item) {
      return <CreatedAt createdAt={item} />;
    },
  },
  {
    title: "Estado",
    key: "status",
    render: function status(item) {
      return <Status item={item} />;
    },
  },
  {
    title: "Total",
    key: "precioTotal",
    render: function totalPrice(item) {
      return <span className="total_price">${item.precioTotal} CLP</span>;
    },
  },
];
function OrdersTable({ data }) {
  return (
    <div className="w-full flex flex-col">
      <div className="order-list-table-wraper">
        <Table
          className="order-list-table"
          columns={columns}
          data={data}
          rowKey="id"
        />
      </div>
    </div>
  );
}

export default OrdersTable;
