export const siteSettings = {
  name: "Lysto",
  description:
    "Lysto es el primer supermercado app en donde TODOS pueden hacer sus compras mensuales generando un ahorro real.",
  author: {
    name: "Lysto",
    websiteUrl: import.meta.env.VITE_APP_URL,
    address: "",
  },
  logo: {
    url: "/assets/images/logo-lysto.png",
    alt: "Lysto",
    href: "/",
    width: 128,
    height: 30,
  },
};
