import Heading from "../../../components/ui/heading";
import AccountLayout from "../../../components/User/AccountLayout";
import PaymentBox from "../../../components/payment/payment-content";
import { useGetPaymentMethodsQuery } from "../../../services/slices/client";
import { useAuth } from "../../../store/useAuth";
import { useEffect } from "react";

function PaymentPage() {
  const auth = useAuth();
  const { data, error, isLoading } = useGetPaymentMethodsQuery(auth.user?.id);
  return (
    <AccountLayout>
      <Heading variant="titleLarge" className="mb-5 md:mb-6 lg:mb-7 lg:-mt-1">
        Mis tarjetas
      </Heading>
      {isLoading ? <h3>Cargando</h3> : <PaymentBox data={data} />}
    </AccountLayout>
  );
}

export default PaymentPage;
