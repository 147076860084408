const AccountLogoutIcon = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M17.1875 22H4.8125C3.67583 22 2.75 21.0751 2.75 19.9375V10.3125C2.75 9.17492 3.67583 8.25 4.8125 8.25H17.1875C18.3242 8.25 19.25 9.17492 19.25 10.3125V19.9375C19.25 21.0751 18.3242 22 17.1875 22ZM4.8125 9.625C4.43392 9.625 4.125 9.933 4.125 10.3125V19.9375C4.125 20.317 4.43392 20.625 4.8125 20.625H17.1875C17.5661 20.625 17.875 20.317 17.875 19.9375V10.3125C17.875 9.933 17.5661 9.625 17.1875 9.625H4.8125Z"
          fill="#8C969F"
        />
        <path
          d="M15.8125 9.625C15.433 9.625 15.125 9.317 15.125 8.9375V5.5C15.125 3.22575 13.2743 1.375 11 1.375C8.72575 1.375 6.875 3.22575 6.875 5.5V8.9375C6.875 9.317 6.567 9.625 6.1875 9.625C5.808 9.625 5.5 9.317 5.5 8.9375V5.5C5.5 2.46675 7.96675 0 11 0C14.0332 0 16.5 2.46675 16.5 5.5V8.9375C16.5 9.317 16.192 9.625 15.8125 9.625Z"
          fill="#8C969F"
        />
        <path
          d="M10.9998 15.5837C9.98875 15.5837 9.1665 14.7614 9.1665 13.7503C9.1665 12.7392 9.98875 11.917 10.9998 11.917C12.0109 11.917 12.8332 12.7392 12.8332 13.7503C12.8332 14.7614 12.0109 15.5837 10.9998 15.5837ZM10.9998 13.292C10.7478 13.292 10.5415 13.4973 10.5415 13.7503C10.5415 14.0033 10.7478 14.2087 10.9998 14.2087C11.2519 14.2087 11.4582 14.0033 11.4582 13.7503C11.4582 13.4973 11.2519 13.292 10.9998 13.292Z"
          fill="#8C969F"
        />
        <path
          d="M11 18.3333C10.6205 18.3333 10.3125 18.0253 10.3125 17.6458V15.125C10.3125 14.7455 10.6205 14.4375 11 14.4375C11.3795 14.4375 11.6875 14.7455 11.6875 15.125V17.6458C11.6875 18.0253 11.3795 18.3333 11 18.3333Z"
          fill="#8C969F"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountLogoutIcon;
