import { lystoApi } from "../config";

export const bannersApi = lystoApi.injectEndpoints({
  endpoints: (build) => ({
    getBanners: build.query({
      query: () => "banners",
    }),
  }),
  overrideExisting: false,
});

export const { useGetBannersQuery } = bannersApi;
