import cn from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { decreaseItemQty, increaseItemQty } from "../../store/slices/cart";
import ProductControl from "../Cart/ProductControl";
import { useNavigate } from "react-router-dom";
import priceFormatter from "../../utils/price-formater";

function ProductCard({ item, quantity, className }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  function handleClick() {
    navigate(`/product/${item.id}`);
  }

  const onDecrement = (e) => {
    e.stopPropagation();
    dispatch(decreaseItemQty({ item }));
  };

  const onIncrement = (e) => {
    e.stopPropagation();
    dispatch(increaseItemQty({ item }));
  };

  return (
    <article
      className={cn(
        "flex flex-col group overflow-hidden rounded-md cursor-pointer transition-all duration-300 shadow-card hover:shadow-cardHover relative h-full",
        className
      )}
      onClick={handleClick}
      title={item.name}
    >
      {/* <Link to={`/product/${item.id}`}>{item.name}</Link>
            <div>{item.precioBase}</div>
            <div>{item.effectivePrice}</div>
            <div>{item.priceVersus}</div>
            <div>-{item.discountPercentage}%</div>
            <div>-{item.discountVsRetail}%</div>
            <div>{item.thumbNail}</div>
            <div>{item.stock}</div>
            <ProductControl 
            quantity={quantity} 
            increase={() => dispatch(increaseItemQty({item}))} 
            decrease={() => dispatch(decreaseItemQty({item}))} /> */}
      <div className="relative flex-shrink-0">
        <div className="flex overflow-hidden max-w-[230px] mx-auto transition duration-200 ease-in-out transform group-hover:scale-105 relative p-5">
          {/* display: inline-block; max-width: 100%; overflow: hidden; position: relative; box-sizing: border-box; margin: 0px; */}
          <img
            src={item.thumbNail}
            className="object-cover bg-skin-thumbnail"
            width={230}
            height={200}
            alt={item.name}
          />
        </div>

        <div className="w-full h-full absolute top-0 pt-2.5 md:pt-3.5 px-3 md:px-4 lg:px-[18px] z-10 -mx-0.5 sm:-mx-1">
          <span className="text-[11px] md:text-xs font-bold text-skin-inverted uppercase inline-block bg-skin-primary rounded-full px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1">
            OFERTA
          </span>
          <div className="inline-block product-count-button-position">
            <ProductControl
              quantity={quantity}
              increase={onIncrement}
              decrease={onDecrement}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col px-3 md:px-4 lg:px-[18px] pb-5 lg:pb-6 lg:pt-1.5 h-full">
        <div className="space-s-2 mb-1 lg:mb-1.5">
          <span className="inline-block font-semibold text-sm sm:text-15px lg:text-base text-skin-base">
            {priceFormatter.format(item.effectivePrice)}
          </span>
          <del className="text-sm text-skin-base text-opacity-70">
            {priceFormatter.format(item.precioBase)}
          </del>
        </div>
        <span className="text-11 sm:text-xs">{item.nombreMarca}</span>
        <h2 className="text-skin-base text-13px sm:text-sm lg:text-13px leading-3.5 sm:leading-5 mb-1.5">
          {item.name}
        </h2>
      </div>
    </article>
  );
}

export default ProductCard;
