import { createSlice } from "@reduxjs/toolkit";

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    address: null,
    paymentMethod: null,
    timeFrame: null,
  },
  reducers: {
    selectAddress: (state, action) => {
      const { address } = action.payload;
      state.address = address;
    },
    selectPayment: (state, action) => {
      const { paymentMethod } = action.payload;
      state.paymentMethod = paymentMethod;
    },
    selectTimeFrame: (state, action) => {
      const { timeFrame } = action.payload;
      state.timeFrame = timeFrame;
    },
    clearCheckout: (state) => {
      state.address = null;
      state.paymentMethod = null;
      state.timeFrame = null;
    },
  },
});

export const { selectAddress, selectPayment, selectTimeFrame, clearCheckout } =
  checkoutSlice.actions;
export default checkoutSlice.reducer;
