import React, { Fragment } from "react";
import CategoriesList from "../../components/Categories/CategoriesList";
import ProductsList from "../../components/Products/ProductsList";

import { useDispatch, useSelector } from "react-redux";
import { Element } from "react-scroll";

import { useGetMiscHomeQuery } from "../../services/slices/misc";
import { useAuth } from "../../store/useAuth";
import Container from "../../components/ui/container";
import { useGetCategoriesQuery } from "../../services/slices/product";
import { useEffect } from "react";
import CategoryDropdownSidebar from "../../components/category/category-dropdown-sidebar";
import AllProductFeed from "../../components/Products/feeds/all-products-feed";
import { useGetBannersQuery } from "../../services/slices/banners";
import BannerGridTwo from "../../components/common/banner-grid-two";
import BannerGrid from "../../components/common/banner-grid";
import BannerAllCarousel from "../../components/common/banner-all-carousel";

function Home(props) {
  const { data, isLoading } = useGetMiscHomeQuery();
  const { data: dataBanners, isLoading: isLoadingBanners } =
    useGetBannersQuery();
  const cart = useSelector((state) => (state || {}).cart);

  return (
    <Container className={undefined} clean={undefined}>
      {isLoading || isLoadingBanners ? (
        <h3>Cargando...</h3>
      ) : (
        <>
          <BannerAllCarousel
            data={dataBanners}
            buttonSize="small"
            className="mb-5 mt-5"
          />
          <Element name="grid" className="flex mb-16 pb-2.5">
            <CategoryDropdownSidebar className="flex-shrink-0 pr-8 xl:pr-8 hidden lg:block w-80 xl:w-[370px]" />
            <AllProductFeed
              data={(data || {}).offers}
              className="w-full xl:-ms-8"
              element={undefined}
            />
          </Element>
        </>
      )}
    </Container>
  );
}

export default Home;
