import cn from "classnames";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchMutation } from "../../services/slices/misc";
import SearchResultLoader from "../ui/loaders/search-result-loader";
import Scrollbar from "../ui/scrollbar";
import SearchBox from "./search-box";
import SearchProduct from "./search-product";

const Search = ({
  className = "md:w-[730px] 2xl:w-[800px]",
  searchId = "search",
  variant = "border",
}) => {
  const [inputFocus, setInputFocus] = useState(false);
  const [searchText, setSearchText] = useState("");

  function clear() {
    setSearchText("");
    setInputFocus(false);
  }
  function enableInputFocus() {
    setInputFocus(true);
  }

  function handleSearch(e) {
    e.preventDefault();
  }

  function handleAutoSearch(e) {
    setSearchText(e.currentTarget.value);
  }

  const [search, { data, isLoading, error, isSuccess }] = useSearchMutation();

  useEffect(() => {
    if (searchText) {
      search({ term: searchText, limit: 20 });
    }
  }, [searchText]);

  return (
    <div
      className={cn(
        "w-full transition-all duration-200 ease-in-out",
        className
      )}
    >
      {" "}
      <div
        className={cn("overlay cursor-pointer", {
          "input-focus-overlay-open": inputFocus === true,
        })}
        onClick={() => clear()}
      />
      <div className="w-full flex flex-col justify-center flex-shrink-0 relative z-30">
        <div className="flex flex-col mx-auto w-full">
          <SearchBox
            searchId={searchId}
            name="search"
            value={searchText}
            onSubmit={handleSearch}
            onChange={handleAutoSearch}
            onClear={clear}
            onFocus={() => enableInputFocus()}
            variant={variant}
          />
        </div>
        {searchText && (
          <div className="w-full absolute top-[56px] start-0 py-2.5 bg-skin-fill rounded-md flex flex-col overflow-hidden shadow-dropDown z-30">
            <Scrollbar className="os-host-flexbox">
              <div className="w-full h-[380px]">
                {isLoading
                  ? Array.from({ length: 15 }).map((_, idx) => (
                      <div
                        key={`search-result-loader-key-${idx}`}
                        className="py-2.5 ps-5 pe-10 scroll-snap-align-start"
                      >
                        <SearchResultLoader
                          key={idx}
                          uniqueKey={`top-search-${idx}`}
                        />
                      </div>
                    ))
                  : data?.productos?.map((item, index) => (
                      <div
                        key={`search-result-key-${index}`}
                        className="py-2.5 ps-5 pe-10 scroll-snap-align-start transition-colors duration-200 hover:bg-skin-two"
                        onClick={clear}
                      >
                        <SearchProduct item={item} key={index} />
                      </div>
                    ))}
              </div>
            </Scrollbar>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
