import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const API_URL = import.meta.env.VITE_API_URL || (
  import.meta.env.NODE_ENV === "development" ||
    import.meta.env.NODE_ENV === "test"
    ? "https://api-dev.lysto.app/api"
    : "https://webapp.lysto.cl/api");
console.log({ API_URL });
export const lystoApi = createApi({
  keepUnusedDataFor:
    import.meta.env.NODE_ENV === "development" ||
      import.meta.env.NODE_ENV === "test"
      ? 120
      : 300,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,

    prepareHeaders: (headers, { getState }) => {
      headers.set("Accept", "application/json");
      const token = getState().auth.token;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Client", "Misc", "Product", "Account", "Order"],
  endpoints: () => ({}),
});
