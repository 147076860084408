import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { selectPayment } from "../../store/slices/checkout";
import PaymentCard from "./card";

function PaymentBox({ data, default_payment }) {
  const dispatch = useDispatch();
  const checkout = useSelector((state) => state.checkout);
  const indexByPaymentMethodId = data.findIndex(function (paymentMethod) {
    return paymentMethod.id === checkout.paymentMethod?.id;
  });

  return (
    <div className="text-15px h-full flex flex-col justify-between -mt-4 md:mt-0">
      <RadioGroup
        value={data[indexByPaymentMethodId]}
        onChange={(paymentMethod) => dispatch(selectPayment({ paymentMethod }))}
        className="md:grid md:grid-cols-2 md:gap-5 auto-rows-auto space-y-4 md:space-y-0"
      >
        <RadioGroup.Label className="sr-only">Default</RadioGroup.Label>
        {data?.map((card, index) => (
          <RadioGroup.Option
            key={index}
            value={card}
            className={({ active, checked }) =>
              `${checked ? "border-skin-primary" : "border-skin-base"}
          border-2 relative shadow-md focus:outline-none rounded-md p-5 block cursor-pointer min-h-[112px] h-full group address__box`
            }
            style={{}}
          >
            <RadioGroup.Label
              as="h3"
              className="text-skin-base font-semibold mb-2 -mt-1"
            >
              {card?.creditCardType}
            </RadioGroup.Label>
            <RadioGroup.Description
              as="div"
              className="text-skin-muted leading-6"
            >
              {card?.numero}
            </RadioGroup.Description>
          </RadioGroup.Option>
        ))}
        {/* <button
          className="w-full border-2 transition-all border-skin-base rounded font-semibold p-5 px-10 cursor-pointer text-skin-primary flex justify-start hover:border-skin-primary items-center min-h-[112px] h-full"
          onClick={() => { }}
        >
          <AiOutlinePlus size={18} className="me-2" />
          Agregar
        </button> */}
      </RadioGroup>
    </div>
  );
}

export default PaymentBox;
