import { useEffect } from "react";
import AccountLayout from "../../../components/User/AccountLayout";
import OrdersTable from "../../../components/order/order-table";
import { useGetMyOrdersQuery } from "../../../services/slices/order";
import Heading from "../../../components/ui/heading";

function OrdersTablePage() {
  const { data, error, isLoading } = useGetMyOrdersQuery();

  return (
    <AccountLayout>
      <Heading variant="titleLarge" className="mb-5 md:mb-6 lg:mb-7 lg:-mt-1">
        Mis pedidos
      </Heading>
      {isLoading ? <h3>Cargando</h3> : <OrdersTable data={data} />}
    </AccountLayout>
  );
}

export default OrdersTablePage;
