import { useEffect } from "react";
import AddressGrid from "../../../components/address/address-grid";
import Heading from "../../../components/ui/heading";
import AccountLayout from "../../../components/User/AccountLayout";
import { useGetAddressQuery } from "../../../services/slices/client";
import { useAuth } from "../../../store/useAuth";

function AddressGridPage() {
  const auth = useAuth();
  const { data, error, isLoading } = useGetAddressQuery(auth.user?.id);

  return (
    <AccountLayout>
      <Heading variant="titleLarge" className="mb-5 md:mb-6 lg:mb-7 lg:-mt-1">
        Mis direcciones
      </Heading>
      {isLoading ? (
        <h3>Cargando</h3>
      ) : (
        <AddressGrid
          address={data}
          default_address={auth.user?.id_direccion_default}
        />
      )}
    </AccountLayout>
  );
}

export default AddressGridPage;
