import cn from "classnames";
import { useSelector } from "react-redux";
import ProductCard from "../ProductCard";

const AllProductFeed = ({ data, element, className = "" }) => {
  const cart = useSelector((state) => (state || {}).cart);
  const matchQuantity = (product, cart) => {
    let quantity = 0;
    if (product.id in cart.productsQty) {
      quantity = cart.productsQty[product.id];
    }
    return <ProductCard key={product.id} item={product} quantity={quantity} />;
  };
  return (
    <div className={cn(className)}>
      {/* <div className="flex items-center justify-between pb-0.5 mb-4 lg:mb-5 xl:mb-6">
            </div> */}
      <div className="grid grid-cols-2 sm:grid-cols-3  lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-3 md:gap-4 2xl:gap-5">
        {(data || []).slice(0, 16).map((product) => {
          return matchQuantity(product, cart);
        })}
      </div>
    </div>
  );
};

export default AllProductFeed;
