import { useState } from "react";
import { useGetAddressQuery } from "../../services/slices/client";
import { useAuth } from "../../store/useAuth";
import Heading from "../ui/heading";
import Button from "../ui/button";
import AddressPage from "./address";
import DeliverySchedule from "./schedule";
import PaymentsCheckout from "./payment";
import { useSelector } from "react-redux";

const data = [
  {
    id: 1,
    title: "Dirección de despacho",
    component: <AddressPage />,
    confirmKey: "address",
    showKey: "direccion",
  },
  {
    id: 2,
    title: "Fecha y Horario",
    component: <DeliverySchedule />,
    confirmKey: "timeFrame",
    showKey: "nombre",
  },
  {
    id: 3,
    title: "Metodo de Pago",
    component: <PaymentsCheckout />,
    confirmKey: "paymentMethod",
    showKey: "numero",
  },
  {
    id: 4,
    title: "Teléfono",
    component: <></>,
    selectedKey: null,
    showKey: null,
  },
];

const CheckoutDetails = () => {
  const checkout = useSelector((state) => state.checkout);
  const [bindIndex, setBindIndex] = useState(0);
  const changeItem = (itemIndex) => {
    if (itemIndex !== bindIndex) {
      setBindIndex(itemIndex);
    }
  };

  return (
    <div className="border border-skin-base bg-skin-fill rounded-md">
      {data?.map((item, index) => {
        return (
          <div
            key={index}
            className={`accordion__panel ${
              !(data?.length - 1 === index) ? "border-b border-skin-base" : ""
            } ${bindIndex !== index ? "collapsed" : "expanded"}
            `}
            onClick={() => changeItem(index)}
          >
            <div
              id={`index_${index}`}
              className="flex items-center p-4 sm:p-8 cursor-pointer pb-6 accordion__button"
            >
              <span className="h-9 w-9 flex items-center justify-center rounded-full border-2 border-current text-skin-primary me-3 font-semibold">
                {index + 1}
              </span>
              <Heading>{item?.title}</Heading>
              <span className="ms-auto">
                {checkout?.[item.confirmKey]?.[item.showKey]}
              </span>
            </div>

            <div
              data-aria-label={`index_${index}`}
              className={`ps-5 sm:ps-9 lg:ps-20 sm:pe-9 pe-5 pb-6 accordion__content`}
            >
              <div className="mb-6">{item?.component}</div>
              {!(data?.length - 1 === index) ? (
                <div className="text-end">
                  <Button
                    onClick={() => changeItem(index + 1)}
                    variant="formButton"
                    className="bg-skin-primary text-skin-inverted rounded font-semibold font-[14px] px-4 py-3 "
                  >
                    Continuar
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CheckoutDetails;
