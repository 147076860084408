import cn from "classnames";
import { useSelector } from "react-redux";
import Alert from "../ui/alert";
import ProductCardLoader from "../ui/loaders/product-card-loader";
import ProductCard from "./ProductCard";

const ProductBundleGrid = ({
  element,
  className = "",
  data,
  error,
  isLoading,
}) => {
  const cart = useSelector((state) => state.cart);
  const matchQuantity = (product, cart) => {
    let quantity = 0;
    if (product.id in cart.productsQty) {
      quantity = cart.productsQty[product.id];
    }
    return (
      <ProductCard
        key={`product--key${product.id}`}
        item={product}
        quantity={quantity}
      />
    );
  };
  return (
    <div className={cn(className)}>
      {error ? (
        <Alert message={error?.message} />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-7 gap-3 md:gap-4 2xl:gap-5">
          {isLoading ? (
            Array.from({ length: 30 }).map((_, idx) => (
              <ProductCardLoader
                key={`product--key-${idx}`}
                uniqueKey={`product--key-${idx}`}
              />
            ))
          ) : (
            <>
              {data?.map((product) => {
                return matchQuantity(product, cart);
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductBundleGrid;
