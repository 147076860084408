import { calculateTotalPriceItem } from "../../store/slices/cart";
import priceFormatter from "../../utils/price-formater";

const CheckoutItem = ({ item }) => {
  return (
    <div className="flex py-4 items-center  border-b border-skin-base ">
      <div className="flex border rounded-md border-skin-base  w-16 h-16 flex-shrink-0">
        <img
          src={item.image}
          alt={"item image"}
          className="rounded-md me-5"
          width={64}
          height={64}
        />
      </div>
      <h6 className="text-15px text-skin-base font-normal ps-3">
        {item.name} X {item.cantidad}
      </h6>
      <div className="flex ms-auto text-15px text-skin-base font-normal  ps-2 flex-shrink-0">
        {priceFormatter.format(item.precioTotal)}
      </div>
    </div>
  );
};

export default CheckoutItem;
