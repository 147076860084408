import ManageAlert from "../../components/alert/ManageAlert";
import ManagedDrawer from "../../components/common/managed-drawer";
import ManagedModal from "../../components/common/managed-modal";
import Container from "../../components/ui/container";
import Footer from "./footer/footer";
import Header from "./header";

function LayoutPage(props) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main
        className="relative flex-grow"
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        <ManageAlert />
        {props.children}
      </main>
      <Footer />
      <ManagedDrawer />
      <ManagedModal />
    </div>
  );
}
export default LayoutPage;
