import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../services/slices/client";
import { clearCredentials } from "../../../store/slices/auth";
import AccountLogoutIcon from "../../icons/account-logout";

function LogoutButton() {
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  async function signout(e) {
    e.preventDefault();
    await logout();
    dispatch(clearCredentials());
    navigate("/");
  }
  return (
    <button
      className="flex items-center text-sm lg:text-15px text-skin-base py-3.5 px-3.5 xl:px-4 2xl:px-5 mb-1 cursor-pointer focus:outline-none"
      onClick={signout}
    >
      <span className="w-9 xl:w-10 flex-shrink-0 flex justify-center">
        <AccountLogoutIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
      </span>
      <span className="ps-1.5">Cerrar Sesion</span>
    </button>
  );
}

export default LogoutButton;
