import Container from "../../../../components/ui/container";
import { footer } from "../data";
import WidgetAbout from "./widget-about-us";
const Widgets = () => {
  const { social } = footer;
  return (
    <Container>
      <div className="grid grid-cols-2 md:grid-cols-7 xl:grid-cols-12 gap-5 sm:gap-9 lg:gap-11 xl:gap-7 pb-[50px]">
        <WidgetAbout
          social={social}
          className="col-span-full sm:col-span-1 md:col-span-3 border-b sm:border-b-0 border-skin-three mb-4 sm:mb-0"
        />{" "}
      </div>
    </Container>
  );
};

export default Widgets;
