import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CategoryHeroSection from "../../components/category/category-hero-section";
import ProductBundleGrid from "../../components/Products/ProductBundleGrid";
import Container from "../../components/ui/container";
import { useGetCategoryQuery } from "../../services/slices/categories";

function CategoryPage() {
  const { id } = useParams();
  const { data, isLoading, error } = useGetCategoryQuery(id);

  return (
    <>
      <CategoryHeroSection
        heroTitle={data?.name}
        heroDescription={data?.descripcion}
      />
      <Container>
        <ProductBundleGrid
          className="mt-7 md:mt-8 xl:mt-10 pb-20"
          data={data?.productos}
          error={error}
          isLoading={isLoading}
        />
      </Container>
    </>
  );
}

export default CategoryPage;
