import { lystoApi } from "../config";

export const clientApi = lystoApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials) => ({
        url: "cliente/login",
        method: "POST",
        body: credentials,
        params: { include: "user" },
        credentials: "include",
      }),
      providesTags: ["Account"],
    }),
    logout: build.mutation({
      query: () => ({
        url: "cliente/logout",
        method: "DELETE",
      }),
      invalidatesTags: ["Account"],
    }),
    getAccountById: build.query({
      query: (id) => `cliente/${id}`,
    }),
    getPaymentMethods: build.query({
      query: (id) => `cliente/${id}/mediospago`,
    }),

    getAddress: build.query({
      query: (id) => `cliente/${id}/direcciones`,
    }),

    getRemoteCart: build.query({
      query: (id) => `cliente/${id}/cart`,
      refetchOnMountOrArgChange: true,
      providesTags: ["Cart"],
    }),
    syncOneRemoteCart: build.mutation({
      query: ({ uid, pid, cantidad }) => ({
        url: `cliente/${uid}/cart/rel/${pid}`,
        method: "PUT",
        body: { cantidad },
      }),
      invalidatesTags: ["Cart"],
    }),
    syncManyRemoteCart: build.mutation({
      query: ({ uid, products }) => ({
        url: `cliente/${uid}/cart`,
        method: "POST",
        body: products,
      }),
      invalidatesTags: ["Cart"],
    }),

    createCheckout: build.mutation({
      query: ({ uid, data }) => ({
        url: `cliente/${uid}/cart/checkout`,
        method: "POST",
        body: data,
      }),
    }),
    deleteRemoteCart: build.mutation({
      query: (id) => ({
        url: `cliente/${id}/cart`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cart"],
    }),
    deleteOneRemoteCart: build.mutation({
      query: ({ uid, pid }) => ({
        url: `cliente/${uid}/cart/rel/${pid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetAccountByIdQuery,
  useGetPaymentMethodsQuery,
  useGetAddressQuery,
  useSyncManyRemoteCartMutation,
  useCreateCheckoutMutation,
  useDeleteRemoteCartMutation,
  useGetRemoteCartQuery,
  useSyncOneRemoteCartMutation,
  useDeleteOneRemoteCartMutation,
} = clientApi;
