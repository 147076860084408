import { lystoApi } from "../config";

export const categoryApi = lystoApi.injectEndpoints({
  endpoints: (build) => ({
    getCategory: build.query({
      query: (cid) => ({
        url: `/ProductoCategoria/${cid}`,
        method: "GET",
        params: { "filter[include]": "productos" },
      }),
    }),
  }),
});
export const { useGetCategoryQuery } = categoryApi;
