import { useEffect } from "react";
import Heading from "../../../components/ui/heading";
import AccountLayout from "../../../components/User/AccountLayout";
import AccountDetails from "../../../components/User/my-account/account-details";
import { useGetAccountByIdQuery } from "../../../services/slices/client";
import { useAuth } from "../../../store/useAuth";

function AccountDetailsPage() {
  const auth = useAuth();
  const { data, error, isLoading, isSuccess } = useGetAccountByIdQuery(
    auth.user?.id
  );
  return (
    <AccountLayout>
      <Heading variant="titleLarge" className="mb-5 md:mb-6 lg:mb-7 lg:-mt-1">
        Mis Datos
      </Heading>
      {isLoading ? <h3>Cargando</h3> : <AccountDetails data={data} />}
    </AccountLayout>
  );
}

export default AccountDetailsPage;
