import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProductSingleDetails from "../../components/Products/ProductSingleDetails";
import Container from "../../components/ui/container";
import Divider from "../../components/ui/divider";
import { useGetProductByIdQuery } from "../../services/slices/product";

function Product() {
  const { id } = useParams();
  const { data, error, isLoading } = useGetProductByIdQuery(id);
  const cart = useSelector((state) => state.cart);

  const matchQuantity = (product, cart) => {
    let quantity = 0;
    if (product.id in cart.productsQty) {
      quantity = cart.productsQty[product.id];
    }
    return <ProductSingleDetails data={product} quantity={quantity} />;
  };

  return (
    <>
      <Divider />
      <div className="pt-6 lg:pt-7">
        <Container>
          {isLoading ? <h3>Cargando...</h3> : matchQuantity(data, cart)}
        </Container>
      </div>
    </>
  );
}

export default Product;
