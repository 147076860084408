import { set } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetCartPreviewQuery } from "../../services/slices/cart";
import {
  useCreateCheckoutMutation,
  useGetRemoteCartQuery,
  useSyncRemoteCartMutation,
} from "../../services/slices/client";
import {
  calculateProductsCartTotal,
  clearCart,
  selectIsEmpty,
  selectProductsCartWithQty,
} from "../../store/slices/cart";
import { clearCheckout } from "../../store/slices/checkout";
import { hideAlert, setAlertView, showAlert } from "../../store/slices/ui";
import { useAuth } from "../../store/useAuth";
import priceFormatter from "../../utils/price-formater";
import Button from "../ui/button";
import CheckoutCardFooter from "./checkout-card-footer-items";
import CheckoutItem from "./checkout-card-item";
import CouponBox from "./coupon";

const CheckoutCard = () => {
  const auth = useAuth();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [createCheckout, { data, isSuccess, isLoading, error }] =
    useCreateCheckoutMutation();
  const {
    data: dataCart,
    isSuccess: isSucessCart,
    isLoading: isLoadingCart,
  } = useGetCartPreviewQuery(auth.user?.id);
  const checkout = useSelector((state) => state.checkout);
  const subtotal = 0;

  const confirmCompleteCheckout = () => {
    const { timeFrame, address, paymentMethod } = checkout;
    const requireAlert = [];
    if (!address) {
      requireAlert.push("Falta dirección de despacho");
    }
    if (!timeFrame) {
      requireAlert.push("Falta ventana horaria");
    }
    if (!paymentMethod) {
      requireAlert.push("Falta metodo de pago");
    }
    dispatch(
      setAlertView({
        view: "REQUIRE_ALERT_VIEW_WITH_CONTENT",
        content: requireAlert,
      })
    );
    return !requireAlert.length;
  };

  const finishCheckout = () => {
    const isComplete = confirmCompleteCheckout();

    if (isComplete) {
      dispatch(hideAlert());
      const data_checkout = {
        idVentana: checkout.timeFrame?.id,
        idDireccion: checkout.address?.id,
        idMediopago: checkout.paymentMethod?.id,
      };
      const payload = { uid: auth.user?.id, data: data_checkout };
      createCheckout(payload);
    } else {
      dispatch(showAlert());
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(
        setAlertView({
          view: "ERROR_ALERT_VIEW_WITH_CONTENT",
          content: error.data,
        })
      );
      dispatch(showAlert());
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearCart());
      dispatch(clearCheckout());
      navigate(`/order/${data.id}`);
    }
  }, [data]);

  useEffect(() => {
    return function cleanup() {
      dispatch(hideAlert());
    };
  }, []);

  const checkoutFooter = [
    {
      id: 1,
      name: "Subtotal",
      price: subtotal,
    },
    {
      id: 2,
      name: "Envio",
      price: 0,
    },
    {
      id: 3,
      name: "Total",
      price: subtotal,
    },
  ];
  return (
    <div className="border border-skin-base bg-skin-fill rounded-md py-1 xl:py-6 px-4 xl:px-7">
      <div className="flex py-4 rounded-md text-sm font-semibold text-heading">
        <span className="text-15px text-skin-base font-medium ">Producto</span>
        <span className="ms-auto flex-shrink-0 text-15px text-skin-base font-medium ">
          Subtotal
        </span>
      </div>

      {isSucessCart && dataCart?.productos?.length ? (
        dataCart.productos?.map((item) => (
          <CheckoutItem item={item} key={item.id} />
        ))
      ) : (
        <p className="text-skin-red text-opacity-70 py-4">
          Tu carro esta vacio
        </p>
      )}

      <CheckoutCardFooter preview={dataCart} />

      <CouponBox coupon={dataCart?.cupon} />

      <Button
        disabled={isLoading}
        variant="formButton"
        className={`w-full mt-8 mb-5 bg-skin-primary text-skin-inverted rounded font-semibold px-4 py-3 transition-all ${
          !dataCart?.productos?.length && "opacity-40 cursor-not-allowed"
        }`}
        onClick={finishCheckout}
      >
        Confirmar Compra
      </Button>
    </div>
  );
};

export default CheckoutCard;
