export const scheduleTimeFrameByDate = (timeframe) => {
  const groupByDate = timeframe.reduce((group, item) => {
    const fecha = item.fecha.split("T")[0];
    group[fecha] = group[fecha] ?? [];
    if (item.isAvailable) {
      group[fecha].push({ ...item, date: new Date(fecha) });
    }
    return group;
  }, {});

  return groupByDate;
};

export const filterAvailable = (schedule) => {
  return schedule.filter((date) => date.isAvailable);
};
