import Copyright from "./copyright";
import Widgets from "./widget/widgets";

const Footer = () => {
  return (
    <footer className="mt-[50px] lg:mt-14 2xl:mt-16">
      <Widgets />
      <Copyright />
    </footer>
  );
};

export default Footer;
