const AccountOrderIcon = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M19.8001 19.0172L18.5403 4.8319C18.5133 4.51697 18.2479 4.27853 17.9375 4.27853H15.3461C15.3101 1.91207 13.3755 0 11.0001 0C8.6246 0 6.69003 1.91207 6.65404 4.27853H4.06263C3.7477 4.27853 3.48676 4.51697 3.45977 4.8319L2.20006 19.0172C2.20006 19.0352 2.19556 19.0532 2.19556 19.0712C2.19556 20.6863 3.67572 22 5.49781 22H16.5023C18.3244 22 19.8046 20.6863 19.8046 19.0712C19.8046 19.0532 19.8046 19.0352 19.8001 19.0172ZM11.0001 1.21472C12.7052 1.21472 14.0954 2.58241 14.1313 4.27853H7.86877C7.90476 2.58241 9.29494 1.21472 11.0001 1.21472ZM16.5023 20.7853H5.49781C4.35507 20.7853 3.42828 20.0294 3.41028 19.0982L4.61601 5.49775H6.64954V7.34233C6.64954 7.67975 6.91948 7.94969 7.25691 7.94969C7.59433 7.94969 7.86427 7.67975 7.86427 7.34233V5.49775H14.1313V7.34233C14.1313 7.67975 14.4013 7.94969 14.7387 7.94969C15.0761 7.94969 15.3461 7.67975 15.3461 7.34233V5.49775H17.3796L18.5898 19.0982C18.5718 20.0294 17.6405 20.7853 16.5023 20.7853Z"
          fill="#8C969F"
          stroke="#8C969F"
          strokeWidth="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountOrderIcon;
