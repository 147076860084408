import { copyWithStructuralSharing } from "@reduxjs/toolkit/dist/query";
import cn from "classnames";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeleteRemoteCartMutation,
  useSyncRemoteCartMutation,
} from "../../services/slices/client";
import {
  calculateProductsCartTotal,
  clearCart,
  selectIsEmpty,
  selectProductsCartWithQty,
} from "../../store/slices/cart";
import { closeDrawer } from "../../store/slices/ui";
import { useAuth } from "../../store/useAuth";
import priceFormatter from "../../utils/price-formater";
import DeleteIcon from "../icons/delete-icon";
import Button from "../ui/button";
import Heading from "../ui/heading";
import Scrollbar from "../ui/scrollbar";
import Text from "../ui/text";
import CartItem from "./cart-item";
import EmptyCart from "./empty-cart";

export default function Cart() {
  const navigate = useNavigate();
  const productsWithQty = useSelector(selectProductsCartWithQty);
  const isEmpty = useSelector(selectIsEmpty);
  const dispatch = useDispatch();

  const toCheckout = () => {
    navigate("/cart/checkout");
  };

  return (
    <div className="flex flex-col w-full h-full justify-between">
      <div className="w-full flex justify-between items-center relative ps-5 md:ps-7 border-b border-skin-base">
        <Heading variant="titleMedium">Tu carro</Heading>
        <div className="flex items-center">
          {!isEmpty && (
            <button
              className="flex flex-shrink items-center text-15px transition duration-150 ease-in focus:outline-none text-skin-base opacity-50 hover:opacity-100 -me-1.5"
              aria-label={"clear-all"}
              onClick={() => dispatch(clearCart())}
            >
              <DeleteIcon />
              <span className="ps-1">Vaciar carro</span>
            </button>
          )}
          <button
            className="flex text-2xl items-center justify-center px-4 md:px-6 py-6 lg:py-7 focus:outline-none transition-opacity text-skin-base hover:opacity-60"
            onClick={() => dispatch(closeDrawer())}
            aria-label="close"
          >
            <IoClose />
          </button>
        </div>
      </div>
      {/* list items */}
      {!isEmpty ? (
        <Scrollbar className="cart-scrollbar w-full flex-grow">
          <div className="w-full px-5 md:px-7">
            {productsWithQty?.map((item) => (
              <CartItem item={item} key={item.id} />
            ))}
          </div>
        </Scrollbar>
      ) : (
        <EmptyCart />
      )}
      {/* end list items */}
      <div className="border-t border-skin-base px-5 md:px-7 pt-5 md:pt-6 pb-5 md:pb-6">
        <div className="flex pb-5 md:pb-7">
          <div className="pe-3">
            <Heading className="mb-2.5">Subtotal:</Heading>
            <Text className="leading-6"></Text>
          </div>
          <div className="flex-shrink-0 font-semibold text-base md:text-lg text-skin-base -mt-0.5 min-w-[80px] text-end">
            {priceFormatter.format(calculateProductsCartTotal(productsWithQty))}
          </div>
        </div>

        <div className="flex flex-col" onClick={() => dispatch(closeDrawer())}>
          <Button
            onClick={toCheckout}
            className={cn(
              "w-full px-5 py-3 md:py-4 flex items-center justify-center bg-heading rounded font-semibold text-sm sm:text-15px text-skin-inverted bg-skin-primary focus:outline-none transition duration-300 hover:bg-opacity-90",
              {
                "cursor-not-allowed !text-skin-base !text-opacity-25 bg-skin-button-disable hover:bg-skin-button-disable":
                  isEmpty,
              }
            )}
          >
            <span className="py-0.5">Continuar</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
