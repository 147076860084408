import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./slices/cart";
import authReducer from "./slices/auth";
import uiReducer from "./slices/ui";
import modalReducer from "./slices/modal";
import checkoutReducer from "./slices/checkout";
import { loadState } from "./local";
import { lystoApi } from "../services/config";
import cartListenerMiddleware from "./cartMiddleware";

const store = configureStore({
  devTools: true,
  reducer: {
    cart: cartReducer,
    auth: authReducer,
    ui: uiReducer,
    modal: modalReducer,
    checkout: checkoutReducer,
    [lystoApi.reducerPath]: lystoApi.reducer,
  },
  preloadedState: loadState(),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([lystoApi.middleware, cartListenerMiddleware.middleware]),
});
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch