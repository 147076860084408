import { RadioGroup } from "@headlessui/react";
import { TiPencil } from "react-icons/ti";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "../ui/button";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/slices/modal";
import { useState } from "react";
import { useEffect } from "react";
import { selectAddress } from "../../store/slices/checkout";

function AddressGrid({ address, default_address }) {
  const dispatch = useDispatch();
  function handlePopupView(item = null) {
    dispatch(openModal({ view: "ADDRESS_VIEW_AND_EDIT", data: item }));
  }
  const checkout = useSelector((state) => state.checkout);
  const indexByAddressId = address.findIndex(function (addrss) {
    return addrss.id === checkout.address?.id;
  });

  return (
    <div className="text-15px h-full flex flex-col justify-between -mt-4 md:mt-0">
      <RadioGroup
        value={address[indexByAddressId]}
        onChange={(address) => dispatch(selectAddress({ address }))}
        className="md:grid md:grid-cols-2 md:gap-5 auto-rows-auto space-y-4 md:space-y-0"
      >
        <RadioGroup.Label className="sr-only">Address</RadioGroup.Label>
        {address?.length > 0 ? (
          address?.map((item, index) => (
            <RadioGroup.Option
              key={index}
              value={item}
              className={({ checked }) =>
                `${checked ? "border-skin-primary" : "border-skin-base"}
                  border-2 relative shadow-md focus:outline-none rounded-md p-5 block cursor-pointer min-h-[112px] h-full group address__box`
              }
            >
              <RadioGroup.Label
                as="h3"
                className="text-skin-base font-semibold mb-2 -mt-1"
              >
                {item.comuna}
              </RadioGroup.Label>
              <RadioGroup.Description
                as="div"
                className="text-skin-muted leading-6"
              >
                {item.direccion}
              </RadioGroup.Description>
              <div className="flex absolute right-3 top-3 z-10 lg:opacity-0 transition-all address__actions">
                <button
                  onClick={() => handlePopupView(item)}
                  className="flex justify-center items-center bg-skin-primary h-6 w-6 rounded-full text-skin-inverted text-opacity-80 text-base"
                >
                  <span className="sr-only">Editar</span>
                  <TiPencil />
                </button>
              </div>
            </RadioGroup.Option>
          ))
        ) : (
          <div className="border-2 border-skin-base rounded font-semibold p-5 px-10 text-skin-red flex justify-start items-center min-h-[112px] h-full">
            No hay direcciones registradas
          </div>
        )}
        {/* <button
                    className="w-full border-2 transition-all border-skin-base rounded font-semibold p-5 px-10 cursor-pointer text-skin-primary flex justify-start hover:border-skin-primary items-center min-h-[112px] h-full"
                    onClick={handlePopupView}
                >
                    <AiOutlinePlus size={18} className="me-2" />
                    Agregar
                </button> */}
      </RadioGroup>

      {/*  <div className="flex sm:justify-end mt-5 md:mt-10 lg:mt-20 save-change-button">
                <Button disabled={true} className="w-full sm:w-auto">Guardar</Button>
            </div> */}
    </div>
  );
}

export default AddressGrid;
