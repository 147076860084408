import { orderBy } from "lodash";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import OrderInformation from "../../components/order/order-information";
import Container from "../../components/ui/container";
import Divider from "../../components/ui/divider";
import { useGetOrderByIdQuery } from "../../services/slices/order";

function OrderPage() {
  const { id } = useParams();
  const { data, isSuccess, error } = useGetOrderByIdQuery(id);

  return (
    <>
      <Divider />
      <Container>
        {data ? <OrderInformation data={data} /> : <>Sin información</>}
      </Container>
      <Divider />
    </>
  );
}

export default OrderPage;
