import { Fragment } from "react";
import useWindowSize from "../../utils/use-window-size";
import PlusIcon from "../icons/plus-icon";
import Counter from "../ui/counter";

function ProductControl({ quantity, increase, decrease, variant = "default" }) {
  const { width } = useWindowSize();
  const iconSize = width > 480 ? "19" : "17";
  return (
    <Fragment>
      {quantity === 0 ? (
        <button
          className="inline-flex bg-skin-primary rounded-full w-8 lg:w-10 h-8 lg:h-10 text-skin-inverted text-4xl items-center justify-center focus:outline-none focus-visible:outline-none"
          aria-label="Count Button"
          data-testid="add"
          onClick={increase}
        >
          <PlusIcon width={iconSize} height={iconSize} opacity="1" />
        </button>
      ) : (
        <Counter
          variant={variant}
          quantity={quantity}
          onDecrease={decrease}
          onIncrease={increase}
        />
      )}
    </Fragment>
  );
}

export default ProductControl;
