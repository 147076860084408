import { IoIosCloseCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import {
  calculateTotalPriceItem,
  decreaseItemQty,
  increaseItemQty,
  removeProduct,
} from "../../store/slices/cart";
import priceFormatter from "../../utils/price-formater";
import Counter from "../ui/counter";

const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  const clearItemFromCart = (item) => {
    dispatch(removeProduct({ item }));
  };

  const onDecrement = (e) => {
    e.stopPropagation();
    dispatch(decreaseItemQty({ item }));
  };

  const onIncrement = (e) => {
    e.stopPropagation();
    dispatch(increaseItemQty({ item }));
  };
  return (
    <div
      className={`group w-full h-auto flex justify-start items-center bg-skin-fill py-4 md:py-7 border-b border-skin-one border-opacity-70 relative last:border-b-0`}
      title={item?.name}
    >
      <div className="relative flex rounded overflow-hidden flex-shrink-0 cursor-pointer w-[90px] md:w-[100px] h-[90px] md:h-[100px]">
        <img
          src={item?.image ?? "/assets/placeholder/cart-item.svg"}
          width={100}
          height={100}
          loading="eager"
          alt={item.name || "Product Image"}
          className="object-cover bg-skin-thumbnail"
        />
        <div
          className="absolute top-0 start-0 h-full w-full bg-black bg-opacity-30 md:bg-opacity-0 flex justify-center items-center transition duration-200 ease-in-out md:group-hover:bg-opacity-30"
          onClick={() => clearItemFromCart(item)}
          role="button"
        >
          <IoIosCloseCircle className="relative text-white text-2xl transform md:scale-0 md:opacity-0 transition duration-300 ease-in-out md:group-hover:scale-100 md:group-hover:opacity-100" />
        </div>
      </div>

      <div className="flex w-full overflow-hidden items-start justify-between">
        <div className="ps-3 md:ps-4">
          {item?.name}
          <div className="text-13px sm:text-sm text-skin-muted mt-1.5 block mb-2">
            X {item.cantidad}
          </div>
          <Counter
            quantity={item.cantidad}
            variant="cart"
            onDecrease={onDecrement}
            onIncrease={onIncrement}
          />
        </div>
        <div className="flex font-semibold text-sm md:text-base text-skin-base leading-5 flex-shrink-0 min-w-[65px] md:min-w-[80px] justify-end">
          {priceFormatter.format(calculateTotalPriceItem(item, item.cantidad))}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
