import { NavLink } from "react-router-dom";
import AccountOrderIcon from "../../icons/account-order";
import AccountSettingsIcon from "../../icons/account-settings";
import LogoutButton from "./LogoutButton";
import cn from "classnames";
import AccountAddressIcon from "../../icons/account-address";
import AccountCardIcon from "../../icons/account-payments";

function AccountNav({ options }) {
  return (
    <nav className="flex flex-col pb-2 md:pb-6 border border-skin-base rounded-md overflow-hidden">
      <NavLink
        to="/mi-cuenta/datos"
        className={({ isActive }) =>
          cn(
            "flex items-center cursor-pointer text-sm lg:text-15px text-skin-base py-3.5 px-3.5 xl:px-4 2xl:px-5 mb-1",
            { "bg-skin-two font-medium": isActive }
          )
        }
      >
        <span className="w-9 xl:w-10 flex-shrink-0 flex justify-center">
          <AccountSettingsIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
        </span>
        <span className="ps-1.5">Mis datos</span>
      </NavLink>
      <NavLink
        to="/mi-cuenta/direcciones"
        className={({ isActive }) =>
          cn(
            "flex items-center cursor-pointer text-sm lg:text-15px text-skin-base py-3.5 px-3.5 xl:px-4 2xl:px-5 mb-1",
            { "bg-skin-two font-medium": isActive }
          )
        }
      >
        <span className="w-9 xl:w-10 flex-shrink-0 flex justify-center">
          <AccountAddressIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
        </span>
        <span className="ps-1.5">Mis direcciones</span>
      </NavLink>
      <NavLink
        to="/mi-cuenta/pedidos"
        className={({ isActive }) =>
          cn(
            "flex items-center cursor-pointer text-sm lg:text-15px text-skin-base py-3.5 px-3.5 xl:px-4 2xl:px-5 mb-1",
            { "bg-skin-two font-medium": isActive }
          )
        }
      >
        <span className="w-9 xl:w-10 flex-shrink-0 flex justify-center">
          <AccountOrderIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
        </span>
        <span className="ps-1.5">Mis pedidos</span>
      </NavLink>
      <NavLink
        to="/mi-cuenta/medios-pago"
        className={({ isActive }) =>
          cn(
            "flex items-center cursor-pointer text-sm lg:text-15px text-skin-base py-3.5 px-3.5 xl:px-4 2xl:px-5 mb-1",
            { "bg-skin-two font-medium": isActive }
          )
        }
      >
        <span className="w-9 xl:w-10 flex-shrink-0 flex justify-center">
          <AccountCardIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />
        </span>
        <span className="ps-1.5">Mis tarjetas</span>
      </NavLink>
      <LogoutButton />
    </nav>
  );
}

export default AccountNav;
