import "rc-drawer/assets/index.css";
import Drawer from "rc-drawer";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "../../store/slices/ui";
import Cart from "../Cart/cart";

const ManagedDrawer = () => {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const contentWrapperCSS = { right: 0 };
  return (
    <Drawer
      open={ui.displayDrawer}
      placement={"right"}
      onClose={() => dispatch(closeDrawer())}
      handler={false}
      showMask={true}
      level={null}
      contentWrapperStyle={contentWrapperCSS}
    >
      {ui.drawerView === "CART_SIDEBAR" && <Cart />}
    </Drawer>
  );
};

export default ManagedDrawer;
