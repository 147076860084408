import { useNavigate } from "react-router-dom";
import CartButton from "../../components/Cart/cart-button";
import Search from "../../components/common/search";
import UserIcon from "../../components/icons/user-icons";
import Container from "../../components/ui/container";
import Logo from "../../components/ui/logo";
import AuthMenu from "../../components/User/AuthMenu";
import { useAuth } from "../../store/useAuth";
import { ROUTES } from "../../utils/routes";

function Header(props) {
  const auth = useAuth();
  console.log(auth);
  let navigate = useNavigate();
  const handleLogin = () => {
    navigate(ROUTES.LOGIN);
  };
  return (
    <header
      id="siteHeader"
      className="header-two sticky-header sticky top-0 z-20 lg:relative w-full h-16 lg:h-auto"
    >
      <div className="innerSticky w-screen lg:w-full transition-all duration-200 ease-in-out body-font bg-skin-secondary z-20">
        <Container className="top-bar h-16 lg:h-auto flex items-center justify-between py-3">
          <Logo className="logo -mt-1.5 md:-mt-1" />
          <Search
            searchId="top-bar-search"
            className="hidden lg:flex lg:max-w-[650px] 2xl:max-w-[800px] lg:ms-8 lg:me-5"
          />
          <div className="flex flex-shrink-0 space-s-5 xl:space-s-7">
            <div className="hidden lg:flex items-center flex-shrink-0 ">
              <UserIcon className="text-skin-base text-opacity-40" />
              <AuthMenu
                isAuthorized={auth.user}
                href={ROUTES.ACCOUNT}
                btnProps={{
                  children: "Ingresa",
                  onClick: handleLogin,
                }}
              >
                Hola {auth.user?.username}
              </AuthMenu>
            </div>
            <CartButton className="hidden lg:flex" />
          </div>
        </Container>
      </div>
    </header>
  );
}
export default Header;
