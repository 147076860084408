import { Link } from "react-router-dom";

const AuthMenu = ({ isAuthorized, href, btnProps, children }) => {
  return isAuthorized ? (
    <Link
      to={href}
      className="text-sm lg:text-15px text-skin-base font-normal focus:outline-none ms-2 capitalize"
    >
      {children}
    </Link>
  ) : (
    <button
      className="text-sm lg:text-15px text-skin-base font-normal focus:outline-none ms-2"
      aria-label="Authentication"
      {...btnProps}
    />
  );
};

export default AuthMenu;
