import { SwiperSlide } from "swiper/react";
import BannerCard from "../cards/banner-card";
import Carousel from "../ui/carousel/carousel";

const breakpoints = {
  "1536": {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  "1280": {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  "1024": {
    slidesPerView: 3,
    spaceBetween: 16,
  },
  "768": {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  "520": {
    slidesPerView: 2,
    spaceBetween: 12,
  },
  "0": {
    slidesPerView: 1,
  },
};

const BannerAllCarousel = ({
  data,
  className = "mb-6",
  buttonSize = "default",
}) => {
  return (
    <div className={className}>
      <Carousel
        autoplay={true}
        breakpoints={breakpoints}
        buttonSize={buttonSize}
        prevActivateId="all-banner-carousel-button-prev"
        nextActivateId="all-banner-carousel-button-next"
      >
        {data?.map((banner) => (
          <SwiperSlide key={`all-banner--key${banner.id}`}>
            <BannerCard banner={banner} effectActive={false} />
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
};

export default BannerAllCarousel;
