import { debounce } from "lodash";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { saveState } from "@store/local";
import store from "@store/store";

//import Cart from '@views/Cart/Cart';
import Cart from "@components/Cart/cart";

import Home from "@views/Home/Home";
import Product from "@views/Product/Product";
import "@styles/scrollbar.css";
import "@styles/custom-plugins.css";

import "@styles/tailwind.css";
import "@styles/swiper-carousel.css";
import LayoutPage from "@views/Layout/page";
import SignInPage from "@views/User/signin";
import AccountDetailsPage from "@views/User/my-account/account-settings";
import OrdersTablePage from "@views/User/my-account/orders";
import AddressGridPage from "@views/User/my-account/address";
import PaymentPage from "@views/User/my-account/payment";
import CheckoutPage from "@views/checkout";
import CategoryPage from "@views/Category/Category";
import OrderPage from "@views/order/Order";

store.subscribe(
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={import.meta.env.PUBLIC_URL}>
        <LayoutPage>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="product">
              <Route path=":id" element={<Product />} />
            </Route>
            <Route path="category">
              <Route path=":id" element={<CategoryPage />} />
            </Route>
            <Route path="/cart" element={<Cart />} />
            <Route path="/cart/checkout" element={<CheckoutPage />} />
            <Route path="/order">
              <Route path=":id" element={<OrderPage />} />
            </Route>
            <Route path="/login" element={<SignInPage />} />
            <Route path="/mi-cuenta">
              <Route index element={<AccountDetailsPage />} />
              <Route path="datos" element={<AccountDetailsPage />} />
              <Route path="direcciones" element={<AddressGridPage />} />
              <Route path="pedidos" element={<OrdersTablePage />} />
              <Route path="medios-pago" element={<PaymentPage />} />
            </Route>
          </Routes>
        </LayoutPage>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
