import { lystoApi } from "../config";

export const orderApi = lystoApi.injectEndpoints({
  endpoints: (build) => ({
    getMyOrders: build.query({
      query: () => "pedido",
      providesTags: ["Account", "Order"],
    }),
    getOrderById: build.query({
      query: (pid) => `/pedido/${pid}/resumen`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetMyOrdersQuery, useGetOrderByIdQuery } = orderApi;
