import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal";
import Button from "../../ui/button";
import CloseButton from "../../ui/close-button";
import Input from "../../ui/form/input";
import Heading from "../../ui/heading";

const AddAddressForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const dispatch = useDispatch();

  function onSubmit(values, e) {
    console.log(values, "Add Address");
  }
  return (
    <div className="w-full md:w-[600px] lg:w-[900px] xl:w-[1000px] mx-auto p-5 sm:p-8 bg-skin-fill rounded-md">
      <CloseButton onClick={() => dispatch(closeModal())} />
      <Heading variant="title" className="mb-8 -mt-1.5">
        Agregar Dirección
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-6">
          <Input
            variant="solid"
            label="Dirección"
            {...register("direccion", { required: "Dirección requerida" })}
            error={errors.direccion?.message}
          />
        </div>
        <div className="grid grid-cols-1 mb-6 gap-7">{/* map */}</div>
        <div className="flex w-full justify-end">
          <Button className="h-11 md:h-12 mt-1.5" type="submit">
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddAddressForm;
