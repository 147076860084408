export const footer = {
  social: [
    {
      id: 1,
      path: "https://www.facebook.com/Lysto-111603043719220",
      image: "/assets/images/social/facebook.svg",
      name: "facebook",
      width: 20,
      height: 20,
    },
    {
      id: 2,
      path: "https://twitter.com/lysto_chile",
      image: "/assets/images/social/twitter.svg",
      name: "twitter",
      width: 20,
      height: 20,
    },
    {
      id: 3,
      path: "https://www.instagram.com/lysto_cl/",
      image: "/assets/images/social/instagram.svg",
      name: "instagram",
      width: 20,
      height: 20,
    },
  ],
};
