import { lystoApi } from "../config";

export const miscApi = lystoApi.injectEndpoints({
  endpoints: (build) => ({
    getMiscHome: build.query({
      query: () => "/Misc/home",
    }),
    getStoreHome: build.query({
      query: () => "/Misc/store",
    }),
    search: build.mutation({
      query: (data) => ({
        url: "/Misc/search",
        method: "POST",
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetMiscHomeQuery, useSearchMutation, useGetStoreHomeQuery } =
  miscApi;
