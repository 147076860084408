import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openDrawer, setDrawerView } from "../../store/slices/ui";
import CartIcon from "../icons/cart-icon";

const CartButton = ({
  className,
  iconClassName = "text-skin-base text-opacity-40",
  hideLabel,
  isShowing,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  function handleClick() {
    //navigate('/cart');
    dispatch(setDrawerView({ view: "CART_SIDEBAR" }));
    return dispatch(openDrawer());
  }
  const cart = useSelector((state) => state.cart);
  return (
    <button
      className={cn(
        "flex items-center justify-center flex-shrink-0 h-auto focus:outline-none transform",
        className
      )}
      onClick={handleClick}
      aria-label="cart-button"
    >
      <div className="flex items-center relative">
        <CartIcon className={cn(iconClassName)} />
        <span className="cart-counter-badge flex items-center justify-center bg-skin-primary text-skin-inverted absolute -top-2.5 start-2.5 rounded-full font-bold">
          {cart.cartLength}
        </span>
      </div>
      {!hideLabel && (
        <span className="text-sm lg:text-15px text-skin-base font-normal ms-2">
          Carro
        </span>
      )}
    </button>
  );
};

export default CartButton;
