import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { clientApi } from "../services/slices/client";
import { setCredentials } from "./slices/auth";
import cart, {
  clearCart,
  decreaseItemQty,
  increaseItemQty,
  removeProduct,
} from "./slices/cart";

const cartListenerMiddleware = createListenerMiddleware();

cartListenerMiddleware.startListening({
  matcher: isAnyOf(increaseItemQty, decreaseItemQty),
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    if (state.auth.user) {
      const uid = state.auth.user.id;
      const pid = action.payload.item?.id;
      const cantidad = state.cart.productsQty[pid];

      listenerApi.dispatch(
        clientApi.endpoints.syncOneRemoteCart.initiate({ uid, pid, cantidad })
      );
    }
  },
});

cartListenerMiddleware.startListening({
  actionCreator: removeProduct,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    if (state.auth.user) {
      const uid = state.auth.user.id;
      const pid = action.payload.item?.id;

      listenerApi.dispatch(
        clientApi.endpoints.deleteOneRemoteCar.initiate({ uid, pid })
      );
    }
  },
});

cartListenerMiddleware.startListening({
  actionCreator: clearCart,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    if (state.auth.user) {
      const uid = state.auth.user.id;
      listenerApi.dispatch(clientApi.endpoints.deleteRemoteCart.initiate(uid));
    }
  },
});

cartListenerMiddleware.startListening({
  actionCreator: setCredentials,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const uid = state.auth.user.id;
    const { data, isSuccess } = await listenerApi.dispatch(
      clientApi.endpoints.getRemoteCart.initiate(uid)
    );
    if (isSuccess) {
      listenerApi.dispatch(clearCart());
      listenerApi.dispatch(clientApi.endpoints.deleteRemoteCart.initiate(uid));
    }
  },
});
export default cartListenerMiddleware;
