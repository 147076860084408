import cn from "classnames";
import { useNavigate } from "react-router-dom";
import slugify from "../../utils/slug";

function SidebarMenuItem({ className, item, depth = 0 }) {
  const { slug, name, children: items, thumbNail } = item;
  let navigate = useNavigate();

  function handleClick() {
    navigate(`/category/${item.id}`);
  }
  return (
    <>
      <li
        className={`flex justify-between items-center transition text-skin-base text-opacity-70 ${
          className
            ? className
            : "text-sm md:text-15px hover:bg-skin-two border-t border-skin-base first:border-t-0 px-3.5 2xl:px-4 py-3 xl:py-3.5 2xl:py-2.5 3xl:py-3"
        }`}
      >
        <button
          onClick={handleClick}
          className={cn(
            "flex items-center w-full text-start outline-none focus:outline-none focus:ring-0 focus:text-skin-base"
          )}
        >
          {thumbNail && (
            <div className="inline-flex flex-shrink-0 2xl:w-12 2xl:h-12 3xl:w-auto 3xl:h-auto">
              <img
                src={thumbNail ?? "/assets/placeholder/category-small.svg"}
                alt={name}
                width={40}
                height={40}
              />
            </div>
          )}
          <span className="text-skin-base capitalize ps-2.5 md:ps-4 2xl:ps-3 3xl:ps-4">
            {name}
          </span>
        </button>
      </li>
    </>
  );
}

function SidebarMenu({ items, className }) {
  return (
    <ul className={cn(className)}>
      {items?.map((item) => (
        <SidebarMenuItem
          key={`${slugify(item.name)}-key-${item.id}`}
          item={item}
        />
      ))}
    </ul>
  );
}

export default SidebarMenu;
