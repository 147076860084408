import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/slices/modal";
import AddAddressForm from "./form/add-address";
import Modal from "./modal/modal";

const AddressPopup = () => <AddAddressForm />;

const ManagedModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  return (
    <Modal open={modal.isOpen} onClose={() => dispatch(closeModal())}>
      {modal.view === "ADDRESS_VIEW_AND_EDIT" && <AddressPopup />}
    </Modal>
  );
};

export default ManagedModal;
