import React from "react";
import { useDispatch } from "react-redux";
import { decreaseItemQty, increaseItemQty } from "../../store/slices/cart";
import priceFormatter from "../../utils/price-formater";
import ProductControl from "../Cart/ProductControl";
import ThumbnailCarousel from "../ui/carousel/thumbnail-carousel";
import Counter from "../ui/counter";
import ProductDetailsTab from "./ProductDetailsTab";

function ProductSingleDetails({ data, quantity }) {
  const dispatch = useDispatch();
  const onDecrement = (e) => {
    e.stopPropagation();
    dispatch(decreaseItemQty({ item: data }));
  };

  const onIncrement = (e) => {
    e.stopPropagation();
    dispatch(increaseItemQty({ item: data }));
  };
  return (
    <div className="pt-6 md:pt-7 pb-2">
      {/* <ul>
                <li>{quantity}</li>
                <li>{data.name}</li>
                <li>{data.nombreMarca}</li>
                <li>{data.descripcion}</li>
                <li>{data.nombre_categoria}</li>
                <li>{data.precioBase}</li>
                <li>{data.descuento}</li>
                <li>{data.effectivePrice}</li>
                <li>{data.priceVersus}</li>
                <li>-{data.discountPercentage}%</li>
                <li>-{data.discountVsRetail}%</li>
                <li>{data.stock}</li>
                <li><img src={data.image} alt={data.name} /></li>
            </ul>
            {
                data.gallery.map(
                    (imageUrl) => { return (<img key={imageUrl} src={imageUrl} />) })
            } */}
      <div className="lg:grid grid-cols-10 gap-7 2xl:gap-8">
        <div className="col-span-5 xl:col-span-6 overflow-hidden mb-6 md:mb-8 lg:mb-0">
          {data.gallery.length ? (
            <ThumbnailCarousel
              gallery={data?.gallery}
              thumbnailClassName="xl:w-[700px] 2xl:w-[900px]"
              galleryClassName="xl:w-[150px] 2xl:w-[170px]"
            />
          ) : (
            <div className="w-auto flex items-center justify-center">
              <img
                src={data.image}
                className="object-cover bg-skin-thumbnail"
                width={900}
                height={680}
                alt={data.name}
              />
            </div>
          )}
        </div>

        <div className="flex-shrink-0 flex flex-col col-span-5 xl:col-span-4 xl:ps-2">
          <div className="pb-3 lg:pb-5">
            <div className="md:mb-2.5 block -mt-1.5">
              <h2 className="text-skin-base text-lg md:text-xl xl:text-2xl font-medium transition-colors duration-300">
                {data.name}
              </h2>
            </div>

            <div className="flex items-center mt-5">
              <div className="text-skin-base font-bold text-base md:text-xl xl:text-[22px]">
                {priceFormatter.format(data.effectivePrice)}
              </div>
              {data.discountPercentage && (
                <>
                  <del className="text-sm md:text-15px ps-3 text-skin-base text-opacity-50">
                    {priceFormatter.format(data.precioBase)}
                  </del>
                  <span className="inline-block rounded font-bold text-xs md:text-sm bg-skin-tree bg-opacity-20 text-skin-tree uppercase px-2 py-1 ms-2.5">
                    - {data.discountPercentage}%
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="pb-2">
            {/* check that item isInCart and place the available quantity or the item quantity */}
            {/* {isEmpty(variations) && (
                    <>
                      {Number(quantity) > 0 || !outOfStock ? (
                        <span className="text-sm font-medium text-skin-yellow-two">
                          {t('text-only') +
                            ' ' +
                            quantity +
                            ' ' +
                            t('text-left-item')}
                        </span>
                      ) : (
                        <div className="text-base text-red-500 whitespace-nowrap">
                          {t('text-out-stock')}
                        </div>
                      )}
                    </>
                  )} */}
          </div>

          <div className="pt-1.5 lg:pt-3 xl:pt-4 space-y-2.5 md:space-y-3.5">
            <ProductControl
              quantity={quantity}
              variant="single"
              increase={onIncrement}
              decrease={onDecrement}
            />
            {/* <Counter
                    variant="single"
                    value={selectedQuantity}
                    onIncrement={() => setSelectedQuantity((prev) => prev + 1)}
                    onDecrement={() =>
                      setSelectedQuantity((prev) => (prev !== 1 ? prev - 1 : 1))
                    }
                    disabled={
                      isInCart(item.id)
                        ? getItemFromCart(item.id).quantity + selectedQuantity >=
                          Number(item.stock)
                        : selectedQuantity >= Number(item.stock)
                    }
                  /> */}

            {/* <Button
                    onClick={addToCart}
                    className="w-full px-1.5"
                    disabled={!isSelected}
                    loading={addToCartLoader}
                  >
                    <CartIcon color="#ffffff" className="me-3" />
                    {t('text-add-to-cart')}
                  </Button> */}
            <div className="grid grid-cols-2 gap-2.5">
              {/* <Button
                      variant="border"
                      onClick={addToWishlist}
                      loading={addToWishlistLoader}
                      className={`group hover:text-skin-primary ${
                        favorite === true && 'text-skin-primary'
                      }`}
                    >
                      {favorite === true ? (
                        <IoIosHeart className="text-2xl md:text-[26px] me-2 transition-all" />
                      ) : (
                        <IoIosHeartEmpty className="text-2xl md:text-[26px] me-2 transition-all group-hover:text-skin-primary" />
                      )}
      
                      {t('text-wishlist')}
                    </Button> */}
              <div className="relative group">
                {/* <Button
                        variant="border"
                        className={`w-full hover:text-skin-primary ${
                          shareButtonStatus === true && 'text-skin-primary'
                        }`}
                        onClick={handleChange}
                      >
                        <IoArrowRedoOutline className="text-2xl md:text-[26px] me-2 transition-all group-hover:text-skin-primary" />
                        {t('text-share')}
                      </Button> */}
                {/* <SocialShareBox
                        className={`absolute z-10 end-0 w-[300px] md:min-w-[400px] transition-all duration-300 ${
                          shareButtonStatus === true
                            ? 'visible opacity-100 top-full'
                            : 'opacity-0 invisible top-[130%]'
                        }`}
                        shareUrl={productUrl}
                      /> */}
              </div>
            </div>
          </div>
          {/* {data?.tag && (
                  <ul className="pt-5 xl:pt-6">
                    <li className="text-sm md:text-15px text-skin-base text-opacity-80 inline-flex items-center justify-center me-2 relative top-1">
                      <LabelIcon className="me-2" /> {t('text-tags')}:
                    </li>
                    {data?.tag?.map((item: any) => (
                      <li className="inline-block p-[3px]" key={`tag-${item.id}`}>
                        <TagLabel data={item} />
                      </li>
                    ))}
                  </ul>
                )} */}
        </div>
      </div>
      <ProductDetailsTab details={data.descripcion} />
    </div>
  );
}

export default ProductSingleDetails;
